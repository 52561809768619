import React from "react"

class OurStory extends React.Component {
  render() {
    let videoLink = this.props.data.nodes[0].field_our_story_youtube_link
    let linkCode = videoLink.substring(videoLink.length - 11, videoLink.length)
    const ourStoryVideoLink = "https://www.youtube.com/embed/" + linkCode

    return (
      <div>
        <div className="our_story_parent_container"> </div>

        <div className="our_story_heading our_story_top_heading">
          <p>
            Our&nbsp;
            <span className="our_story_heading_color_letters">Story</span>
          </p>
        </div>

        <div className="our_story_container">
          <div className="our_story_first_area">
            <span
              className="our_story_text"
              dangerouslySetInnerHTML={{
                __html: this.props.data.nodes[0].field_our_story_body.processed,
              }}
            />
          </div>
          <div className=" our_story_second_area">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                className="embed-responsive-item rounded-lg"
                src={ourStoryVideoLink}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write;
                    encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen="allowfullscreen"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default OurStory
