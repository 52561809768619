import React from "react"

class WhatWeDo extends React.Component {
  render() {
    return (
      <div className="mx-md-5" style={{ marginBottom: "100px" }}>
        <div className="what_we_do_heading">
          <p>Studi is designed to</p>
        </div>
        <div className="mx-2">
          <div className="row">
            <div className="col-lg-6">
              <div className="wwd_border">
                <i className="fa fa-circle dot_wwd mt-2" aria-hidden="true" />
                <span className="wwd_lbl">
                  Enable your child to have a deep conceptual understanding of
                  every topic that he or she learns.
                </span>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="wwd_border">
                <i className="fa fa-circle dot_wwd mt-2" aria-hidden="true" />
                <span className="wwd_lbl">
                  Ensure that your child revises his or her lessons
                  systematically through efficient planning and practice.
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="wwd_border">
                <i
                  className="fa fa-circle dot_wwd wwd_dot_padding"
                  aria-hidden="true"
                />
                <span className="wwd_lbl wwd_lbl_margin_top">
                  Allow you to track your child's progress without being
                  intrusive.
                </span>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="wwd_border">
                <i
                  className="fa fa-circle dot_wwd wwd_dot_padding"
                  aria-hidden="true"
                />
                <span className="wwd_lbl wwd_lbl_margin_top">
                  Align precisely with your child’s prescribed syllabus.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default WhatWeDo
