import React from "react"
import rewardImage from "../images/rewards.svg"

class Rewards extends React.Component {
  render() {
    return (
      <div>
        <div className="rewards-page-heading font-samsung">
          <p className="color-black">
            Awards &&nbsp;
            <span className="heading-blue">Recognition</span>
          </p>
        </div>

        <div className="container-fluid">
          <div className="row">
            {this.props.data.nodes.map(item => {
              return (
                <div className="reward-card col-xl-3 col-sm-5 my-5 mx-3 nopadding">
                  <img
                    className="reward-logo"
                    src={rewardImage}
                    alt="Reward logo"
                    title="reward_logo"
                  />
                  <div className="reward-body">
                    <div
                      className="reward-heading font-samsung color-blue"
                      dangerouslySetInnerHTML={{
                        __html: item.field_awards_and_recognitions_la.processed,
                      }}
                    />
                    <div
                      className="reward-text font-lato color-black"
                      dangerouslySetInnerHTML={{
                        __html: item.field_awards_and_recognitions_te.processed,
                      }}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default Rewards
