import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import SEO from "../components/seo"
import WhatWeDo from "../components/whatWeDo"
import OurStory from "../components/ourStory"
import PropTypes from "prop-types"
import Rewards from "../components/rewards"

const propTypes = {
  data: PropTypes.string.isRequired,
}

export default function AboutUsPage(props) {
  return (
    <Layout>
    <Helmet>
      <script type="application/ld+json">
        {`{ 
        "@context": "https://schema.org", 
        "@type": "VideoObject", 
        "name": "Tata Studi | E-Learning App | Application", 
        "description": "Tata Studi | Application", 
        "thumbnailUrl": "https://i9.ytimg.com/vi/c5tg9DEz8_g/mq2.jpg?sqp=CKDtgoYG&rs=AOn4CLB3iEVWk1jyLKZuVKO-R7WEovHNIg", 
        "uploadDate": "2020-12-18", 
        "contentUrl": " https://www.tatastudi.com/aboutus/", 
        "embedUrl": " https://www.youtube.com/watch?v=c5tg9DEz8_g" 
} `}
      </script>
    </Helmet>
    <SEO
      title="Know More About Us - Tata Studi | Tata ClassEdge"
      description="Our goal in Tata Studi is to generate unique & effective 
     study habits in the students to make them independent learners. 
     We help students prepare for their exams thoroughly with a highly 
     effective learning method recommended by learning scientists."
      lang="en"
    />
    <div
      className="container-fluid nopadding"
      style={{ maxWidth: "100%", overflowX: "hidden" }}
    >
      <div>
        <div className="pageBanner aboutPage bannerText">
          <h1 className="displayNone">Know More About Us</h1>
          <h2>About Us</h2>
        </div>
      </div>
      <OurStory data={props.data.ourStory} />
      <WhatWeDo />

      <Rewards data={props.data.awardsAndRecognitions} />
    </div>
  </Layout>
  )
}

export const query = graphql`
  query {
    ourStory: allNodeAboutUsOurStory {
      nodes {
        field_our_story_body {
          processed
        }
        field_our_story_youtube_link
      }
    }

    awardsAndRecognitions: allNodeAwardsAndRecognitions {
      nodes {
        field_awards_and_recognitions_la {
          processed
        }
        field_awards_and_recognitions_te {
          processed
        }
      }
    }
  }
`



